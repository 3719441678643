import './App.css';
import { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './js/HomePage';
import PC from './js/PC';
import Mobile from './js/Mobile';
import QandA from './js/QandA';

function App() {

  const [PageDisplay, setPageDisplay] = useState('Page1');
  const [PageDisplaySp, setPageDisplaySp] = useState('PageSp1');
  const [ReLoad, setReLoad] = useState(1);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage PageDisplay={PageDisplay} setPageDisplay={setPageDisplay} PageDisplaySp={PageDisplaySp} setPageDisplaySp={setPageDisplaySp} ReLoad={ReLoad} setReLoad={setReLoad} />}></Route>
        <Route path='/PC' element={<PC PageDisplay={PageDisplay} setPageDisplay={setPageDisplay} PageDisplaySp={PageDisplaySp} setPageDisplaySp={setPageDisplaySp} />}></Route>
        <Route path='/Mobile' element={<Mobile PageDisplay={PageDisplay} setPageDisplay={setPageDisplay} PageDisplaySp={PageDisplaySp} setPageDisplaySp={setPageDisplaySp} />}></Route>
        <Route path='/QandA' element={<QandA PageDisplay={PageDisplay} setPageDisplay={setPageDisplay} PageDisplaySp={PageDisplaySp} setPageDisplaySp={setPageDisplaySp} />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
