import '../css/Style.css'
import { useState } from 'react'

import useInterval from 'use-interval'
import Iframe from 'react-iframe'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import img001 from '../image/main_001.png'
import img002 from '../image/main_002.png'
import img003 from '../image/main_003.png'
import img004 from '../image/main_004.png'
import img005 from '../image/main_005.png'
import img006 from '../image/main_006.png'
import img007 from '../image/main_007.png'
import img008 from '../image/main_008.png'
import img009 from '../image/main_009.png'
import img010 from '../image/main_010.png'

import headersp1 from '../image/header-bg-sp1.png'
import headersp2 from '../image/header-bg-sp2.png'
import header from '../image/header-bg.png'
import wave from '../image/wave-yellow.png'
import fish from '../image/fish.png'
import giphy from '../image/giphy.gif'
import BANKSEA from '../image/BANKSEA_logo.jpg'
import Instagram from '../image/Instagram.png'
import LINEBk from '../image/LINE-bk.svg'
import InstagramBk from '../image/Instagram-bk.png'
import InstaIcon1 from '../image/insta-icon1.png'
import InstaIcon2 from '../image/insta-icon2.png'
import InstaImg1 from '../image/Insta-img1.png'
import InstaImg2 from '../image/Insta-img2.png'
import InstaImg3 from '../image/Insta-img3.png'
import InstaImg4 from '../image/Insta-img4.png'
import InstaImg5 from '../image/Insta-img5.png'
import InstaImg6 from '../image/Insta-img6.png'
import InstaIconW from '../image/instagram_white_icon.svg'
import clock from '../image/clock.svg'
import place from '../image/place.svg'
import phone from '../image/phone.svg'
import checkmark from '../image/checkmark.svg'
import naoto from '../image/naoto.png'
import member from '../image/member-001.png'
import starfish from '../image/starfish.svg'

export default function HomePage ({ PageDisplay, setPageDisplay, PageDisplaySp, setPageDisplaySp, ReLoad, setReLoad }) {

    // useState(() => {
    //     const handlePageLoad = () => {
    //         window.location.href = '/';
    //         window.removeEventListener('load', handlePageLoad);
    //     };
    
    //     // ページがロードされたときにイベントリスナーを追加
    //     if (ReLoad === 1) {
    //         window.addEventListener('load', handlePageLoad);
    //         setReLoad(2);
    //     }
    // }, []);
    
    const [TopMenu1, setTopMenu1] = useState('MenuPos Menu1');
    const [TopMenu2, setTopMenu2] = useState('MenuPos Menu2');
    const [TopMenu3, setTopMenu3] = useState('MenuPos Menu2');
    const [TopMenu4, setTopMenu4] = useState('MenuPos Menu2');
    const [TopMenu5, setTopMenu5] = useState('MenuPos Menu2');
    const [TopMenu6, setTopMenu6] = useState('MenuPos Menu2');
    const [OutoPoint, setOutoPoint] = useState(true);

    useInterval(() => {
        setPageDisplay(PageDisplay);
        const targetElement = document.getElementById(PageDisplay);
        if (OutoPoint === true) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            setOutoPoint(false);
        }

        setPageDisplaySp(PageDisplaySp);
        const targetElementSp = document.getElementById(PageDisplaySp);
        if (OutoPoint === true) {
            targetElementSp.scrollIntoView({ behavior: 'smooth' });
            setOutoPoint(false);
        }
    },[100])

    useState(() => {
        if(PageDisplay === 'Page1'){
            setTopMenu1('MenuPos Menu1');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page2'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu1');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page3'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu1');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page4'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu1');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page5'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu1');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page6'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu1');
        }
    })

    const MenuClick1 = () => {
        setMenuAni(!MenuAni);
        setUpBar('UpBar UpBarAni1');
        setDownBar('DownBar DownBarAni1');
        setSlideMenu('SlideMenuOff SlideMenuOffAni');
        setPageDisplay('Page1');
        setPageDisplaySp('PageSp1');
        setTopMenu1('MenuPos Menu1');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
    }
    const MenuClick2 = () => {
        setMenuAni(!MenuAni);
        setUpBar('UpBar UpBarAni1');
        setDownBar('DownBar DownBarAni1');
        setSlideMenu('SlideMenuOff SlideMenuOffAni');
        setPageDisplay('Page2');
        setPageDisplaySp('PageSp2');
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu1');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
    }
    const MenuClick3 = () => {
        setMenuAni(!MenuAni);
        setUpBar('UpBar UpBarAni1');
        setDownBar('DownBar DownBarAni1');
        setSlideMenu('SlideMenuOff SlideMenuOffAni');
        setPageDisplay('Page3');
        setPageDisplaySp('PageSp3');
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu1');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
    }
    const MenuClick4 = () => {
        setMenuAni(!MenuAni);
        setUpBar('UpBar UpBarAni1');
        setDownBar('DownBar DownBarAni1');
        setSlideMenu('SlideMenuOff SlideMenuOffAni');
        setPageDisplay('Page4');
        setPageDisplaySp('PageSp4');
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu1');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
    }
    const MenuClick5 = () => {
        setMenuAni(!MenuAni);
        setUpBar('UpBar UpBarAni1');
        setDownBar('DownBar DownBarAni1');
        setSlideMenu('SlideMenuOff SlideMenuOffAni');
        setPageDisplay('Page5');
        setPageDisplaySp('PageSp5');
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu1');
        setTopMenu6('MenuPos Menu2');
    }
    const MenuClick6 = () => {
        setMenuAni(!MenuAni);
        setUpBar('UpBar UpBarAni1');
        setDownBar('DownBar DownBarAni1');
        setSlideMenu('SlideMenuOff SlideMenuOffAni');
        setPageDisplay('Page6');
        setPageDisplaySp('PageSp6');
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu1');
    }
    const MenuClick7 = () => {
        setMenuAni(!MenuAni);
        setUpBar('UpBar UpBarAni1');
        setDownBar('DownBar DownBarAni1');
        setSlideMenu('SlideMenuOff SlideMenuOffAni');
        setPageDisplay('Page1');
        setPageDisplaySp('PageSp1');
    }

    const [TopImg, setTopImg] = useState(img008);

    const[Count, setCount] = useState(0);
    const[CountUp, setCountUp] = useState(1);
    useInterval(() => {
        setCountUp(1);
        setCount(Count + CountUp);
        console.log(Count);

        if(4 > Count){
            setTopImg(img008);
        }
        if(Count >= 4){
            setTopImg(img001);
        }
        if(Count >= 8){
            setTopImg(img002);
        }
        if(Count >= 12){
            setTopImg(img003);
        }
        if(Count >= 16){
            setTopImg(img004);
        }
        if(Count >= 20){
            setTopImg(img005);
        }
        if(Count >= 24){
            setTopImg(img006);
        }
        if(Count >= 28){
            setTopImg(img007);
        }
        if(Count >= 32){
            setTopImg(img009);
        }
        if(Count >= 36){
            setTopImg(img010);
        }
        if(Count >= 40){
            setCount(0);
        }
    },[1000])

    const [UpBar, setUpBar] = useState('UpBar');
    const [DownBar, setDownBar] = useState('DownBar');
    const [SlideMenu, setSlideMenu] = useState('SlideMenu');
    const [MenuAni, setMenuAni] = useState(false);
    const MenuClick = () => {
        setMenuAni(!MenuAni);
        console.log('うわー' + MenuAni + 'だよ！');

        if(MenuAni === true){
            setUpBar('UpBar UpBarAni1');
            setDownBar('DownBar DownBarAni1');
            setSlideMenu('SlideMenuOff SlideMenuOffAni');
        }
        if(MenuAni === false){
            setUpBar('UpBar UpBarAni2');
            setDownBar('DownBar DownBarAni2');
            setSlideMenu('SlideMenuOn SlideMenuOnAni');
        }
    }

    const [Corse1, setCorse1] = useState('CorseSpFUN');
    const [CorseCheck1, setCorseCheck1] = useState(false);
    const CorseClick1 = () => {
        setCorseCheck1(!CorseCheck1);
        if(CorseCheck1 === true){
            setCorse1('CorseSpFUN PulsFUN1');
        }
        if(CorseCheck1 === false){
            setCorse1('CorseSpFUN MinFUN1');
        }
    }

    const [Corse2, setCorse2] = useState('CorseSpFUN');
    const [CorseCheck2, setCorseCheck2] = useState(false);
    const CorseClick2 = () => {
        setCorseCheck2(!CorseCheck2);
        if(CorseCheck2 === true){
            setCorse2('CorseSpFUN PulsFUN2');
        }
        if(CorseCheck2 === false){
            setCorse2('CorseSpFUN MinFUN2');
        }
    }

    const [Corse3, setCorse3] = useState('CorseSpFUN');
    const [CorseCheck3, setCorseCheck3] = useState(false);
    const CorseClick3 = () => {
        setCorseCheck3(!CorseCheck3);
        if(CorseCheck3 === true){
            setCorse3('CorseSpFUN PulsFUN3');
        }
        if(CorseCheck3 === false){
            setCorse3('CorseSpFUN MinFUN3');
        }
    }

    const [Corse4, setCorse4] = useState('CorseSpFUN');
    const [CorseCheck4, setCorseCheck4] = useState(false);
    const CorseClick4 = () => {
        setCorseCheck4(!CorseCheck4);
        if(CorseCheck4 === true){
            setCorse4('CorseSpFUN PulsFUN4');
        }
        if(CorseCheck4 === false){
            setCorse4('CorseSpFUN MinFUN4');
        }
    }

    return(
        <div className='Base'>
            <div className='PCside'>
                <div className='MenuArea'>
                    <p className={TopMenu1} ><HashLink smooth to='#Page1' onClick={() => {MenuClick1()}}>HOME</HashLink></p>
                    <p className={TopMenu2} ><HashLink smooth to='#Page2' onClick={() => {MenuClick2()}}>ABOUT US</HashLink></p>
                    <p className={TopMenu3} ><HashLink smooth to='#Page3' onClick={() => {MenuClick3()}}>コース紹介</HashLink></p>
                    <p className={TopMenu4} ><HashLink smooth to='#Page4' onClick={() => {MenuClick4()}}>インストラクター</HashLink></p>
                    <p className={TopMenu5} ><HashLink smooth to='#Page5' onClick={() => {MenuClick5()}}>フォトギャラリー</HashLink></p>
                    <p className={TopMenu6} ><HashLink smooth to='#Page6' onClick={() => {MenuClick6()}}>アクセス</HashLink></p>
                    <p className='MenuPos Menu2' ><Link to='/QandA' onClick={() => {MenuClick7()}}>よくあるご質問</Link></p>
                    {/* <p className='MenuPos Menu2' >お問い合わせ</p> */}
                </div>
                <div className='TopMedia'>
                    <img className='ScrollMedia' src={TopImg} alt='TopImg' />
                </div>
                <img className='HeaderGiphy' src={giphy} alt='giphy' />
                <div className='BubbleTextArea'>
                    <p className='BubbleText1'>DIVING IS LIFE</p>
                    <p className='BubbleText2'>DIVING IS LIFE</p>
                    <p className='BubbleText3'>DIVING IS LIFE</p>
                </div>
                <div className='HeaderArea' id='Page1'>
                    <img className='HeaderBANKSEA' src={BANKSEA} alt='BANKSEA' />
                    <img className='HeaderMedia' src={header} alt='header' />
                    <p className='HeaderTitle1'>Diving is Life</p>
                    <p className='HeaderTitle2'>BANK_SEA<br/>ISHIGAKI </p>
                    <p className='HeaderTitle3'>
                        ダイバーでありアーティストでもある私が海の中で<br/>
                        貴方に感動の世界を届けたい. . .<br/>
                        「やってみたい」の気持ちを大切に<br/>
                        共に楽しい最高の思い出を作れる場所
                    </p>
                    {/* <p className='HeaderTitle4'>ReadMore</p> */}
                    <img className='HeaderWave' src={wave} alt='wave' />
                    {/* <Link to='https://lin.ee/er1evDL'><img className='HeaderLINE' src={LINE} alt='LINE' /></Link> */}
                    <Link to='https://www.instagram.com/bank_sea_81?igsh=bzJueGdmNjZvenRo'><img className='HeaderInstagram' src={Instagram} alt='Instagram' /></Link>
                    <p className='HeaderTitle5'>www.BANK_SEA ISHIGAKI .com</p>
                </div>

                <div className='AboutArea' id='Page2'>
                    <p className='AboutBackTitle'>ABOUT<br/>US</p>
                    <img className='HeaderFish' src={fish} alt='fish' />
                    <p className='AboutTitle'>ABOUT US</p>
                    <div className='AboutTitleLine'></div>
                    <p className='AboutText'>
                        どうもBANK/SEA ISHIGAKIと申します。<br/>
                        なぜ、BANK/SEA??と思う方も多いと思いますが<br/>
                        私自身が独学で絵を描くのも好きでありダイビングも好きであり<br/>
                        その２つが融合してそのままショップ名となってしまいました！笑<br/>
                        <br/>
                        BANK/SEA ISHIGAKIではみなさんの<br/>
                        「やってみたい」「挑戦したい」という気持ちを大切に<br/>
                        寄り添っていきたいと思っています。<br/>
                        <br/>
                        私自身が元々保育士をやっていたこともあり<br/>
                        子どもたちの不安や心配の中で、それを一緒に乗り越えて<br/>
                        「やってみたい」「出来た!!」に変わったときの喜びを<br/>
                        今度はみなさんと一緒に味わえたら嬉しいです。
                    </p>
                    <img className='AboutImg' src={naoto} alt='naoto' />
                </div>

                <div className='CorseArea' id='Page3'>
                    <p className='CorseTitle'>コース紹介</p>
                    <div className='CorseTitleLine'></div>
                    <p className='CorseBackTitle'>SERVICE</p>

                    <div className='CorseFUN'>
                        <p className='CorseFUNTitle'>FUN diving<span className='CorseFUNText'>ライセンス保持者のコース</span></p>
                        <div className='CorseFUNin'>
                            <p className='CorseFUNinText1'>地形、マクロ、ワイドと盛りだくさんのポイントから<br/>その日に最高のポイントで潜ります。</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 2ダイブ  ・・・・・・・・・・・・・・・・・・ 18,500円</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 3ダイブ  ・・・・・・・・・・・・・・・・・・ 21,000円</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> フルレンタル   ・・・・・・・・・・・・・・・・ 4000円</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 重器材（BCD.レギュレーター） ・・・・・・・・ 2500円 </p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 軽器材（フィン.マスク.ウェットスーツ）　・・・・1500円</p>
                            {/* <button className='CorseFUNinBtn'>申し込む</button> */}
                        </div>
                    </div>
                    <p className='CorseFUNCaution'>※講習生はレンタル器材永久無料</p>

                    <div className='CorseNovice'>
                        <p className='CorseFUNTitle'>体験ダイビング<span className='CorseNoviceText'>初めての方や不安な方向けのコース</span></p>
                        <div className='CorseNovicein'>
                            <p className='CorseFUNinText1'>１本目はゆっくりと浅瀬のポイントでしっかりと練習をして<br/>２本目ではウミガメやマンタが見れるポイントで泳げます。</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 2ダイブ ・・・・・・・  21,000円（レンタル代込み）</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 3ダイブ ・・・・・・・  26,000円（レンタル代込み）</p>
                            {/* <button className='CorseNoviceinBtn'>申し込む</button> */}
                        </div>
                    </div>

                    <div className='CorseLicense'>
                        <p className='CorseLicenseTitle'>ライセンス講習<span className='CorseLicenseText'>ダイビングを始めていきたい方のコース</span></p>
                        <div className='CorseLicensein'>
                            <p className='CorseFUNinText1'>「やってみたい」を大切にダイビングスキルや知恵を勉強できます。</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> OW  2日間 ・・・・・・・・・・・・・・・・・  55,000円</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> AOW2日間 ・・・・・・・・・・・・・・・・・  55,000円</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> OW & AOW3日間 ・・・・・・・・・・・・・・ 98,000円</p>
                            {/* <button className='CorseNoviceinBtn'>申し込む</button> */}
                        </div>
                    </div>

                    <div className='CorseSchnorchel'>
                        <p className='CorseFUNTitle'>シュノーケル<span className='CorseNoviceText'>初めての方や不安な方向けのコース</span></p>
                        <div className='CorseNovicein'>
                            <p className='CorseFUNinText1'>水面からウミガメや綺麗な珊瑚を楽しめます。</p>
                            <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> １日 ・・・・・・・・・・・・・・・・・・・・ 13,000円</p>
                            {/* <button className='CorseSchnorchelinBtn'>申し込む</button> */}
                        </div>
                    </div>

                    <Link to='https://lin.ee/er1evDL'><button className='CorseBtn'>お申込みは公式LINEから</button></Link>
                </div>

                <div className='InstructorArea'id='Page4'>
                    <p className='InstructorTitle'>インストラクター紹介</p>
                    <div className='InstructorTitleLine'></div>
                    <img className='InstructorImg' src={member} alt='member' />
                    <img className='Instructorwave' src={wave} alt='wave' />
                    <div className='InstructorNaotoArea'>
                        <p className='InstructorNaotoTitle'>NAOTO OHNO</p>
                        <p className='InstructorNaotoText'>大野七音（おおのなおと）</p>
                        <img className='InstructorStarfishImg1' src={starfish} alt='starfish' />
                        <p className='InstructorText1'>あだ名：ドレミ♪</p>
                        <img className='InstructorStarfishImg2' src={starfish} alt='starfish' />
                        <p className='InstructorText2'>生年月日：1997/6/17</p>
                        <img className='InstructorStarfishImg3' src={starfish} alt='starfish' />
                        <p className='InstructorText3'>出身地：大都会埼玉県</p>
                        <img className='InstructorStarfishImg4' src={starfish} alt='starfish' />
                        <p className='InstructorText4'>
                            資格：PADIインストラクター・潜水士・小型船舶免許二級・<br/>
                            　　　特定小型船舶操縦士・保育士免許・幼稚園教論免許
                        </p>
                        <img className='InstructorStarfishImg5' src={starfish} alt='starfish' />
                        <p className='InstructorText5'>
                            メッセージ<br/>
                            海なし県で保育士をやっていた際になんとなくきた石垣島...<br/>
                            海の壮大さと沢山の人や生き物との出会いに感激受けあっとい<br/>
                            う間に住み着いてしまいました。<br/>
                            石垣島の海では小さな生物から大きな生物までたくさんの魅力<br/>
                            のある海になっているのでそんな海の世界を皆さんと共に感じ<br/>
                            ることができたら嬉しいです。
                        </p>
                    </div>
                </div>

                <div className='FontGalleryArea' id='Page5'>
                    <p className='FontGalleryTitle'>フォトギャラリー</p>
                    <div className='FontGalleryTitleLine'></div>
                    <div className='FontGalleryInstaArea'>
                        <div className='FontGalleryShop'>
                            <img className='FontGalleryIcon' src={InstaIcon1} alt='FontGalleryIcon' />
                            <p className='FontGalleryName1'>diving shop</p>
                            <p className='FontGalleryName2'>bank_sea_81</p>
                            <Link to='https://www.instagram.com/bank_sea_81/' target="_blank">
                                <div className='InstaIconWArea'>
                                    <img className='InstaIconW' src={InstaIconW} alt='InstaIconW' />
                                    <p className='InstaIconWText'>Instagramでフォロー</p>
                                </div>
                            </Link>
                            <Link to='https://www.instagram.com/p/C9tz6mZy0R_/?img_index=1' target="_blank"><img className='FontGalleryImg1' src={InstaImg1} alt='FontGalleryImg1' /></Link>
                            <Link to='https://www.instagram.com/p/C9rdWPqSrOT/' target="_blank"><img className='FontGalleryImg1' src={InstaImg2} alt='FontGalleryImg1' /></Link>
                            <Link to='https://www.instagram.com/p/C9mpO36ypRc/?img_index=1' target="_blank"><img className='FontGalleryImg1' src={InstaImg3} alt='FontGalleryImg1' /></Link>
                        </div>
                        <div className='FontGalleryArt'>
                            <img className='FontGalleryIcon' src={InstaIcon2} alt='FontGalleryIcon' />
                            <p className='FontGalleryName1'>illustration/art</p>
                            <p className='FontGalleryName2'>bank_sea</p>
                            <Link to='https://www.instagram.com/bank_sea/following/' target="_blank">
                                <div className='InstaIconWArea'>
                                    <img className='InstaIconW' src={InstaIconW} alt='InstaIconW' />
                                    <p className='InstaIconWText'>Instagramでフォロー</p>
                                </div>
                            </Link>
                            <Link to='https://www.instagram.com/p/CulvxqPPAiH/?img_index=1' target="_blank"><img className='FontGalleryImg1' src={InstaImg4} alt='FontGalleryImg1' /></Link>
                            <Link to='https://www.instagram.com/p/CrLDCXRvZeW/' target="_blank"><img className='FontGalleryImg1' src={InstaImg5} alt='FontGalleryImg1' /></Link>
                            <Link to='https://www.instagram.com/p/CzlOcGcpm43/' target="_blank"><img className='FontGalleryImg1' src={InstaImg6} alt='FontGalleryImg1' /></Link>
                        </div>
                    </div>
                </div>

                <div className='ShopArea' id='Page6'>
                    <p className='ShopTitle'>アクセス</p>
                    <div className='ShopTitleLine'></div>
                    <p className='ShopBackTitle'>ACCESS</p>
                    <img className='Shopclock' src={clock} alt='clock' />
                    <p className='ShopclockText1'>[ 営業時間 ]　7:00〜20:00</p>
                    <img className='Shopplace' src={place} alt='place' />
                    <p className='ShopplaceText1'>〒907-0004</p>
                    <p className='ShopplaceText2'>沖縄県石垣市登野城905-2</p>
                    <p className='ShopplaceText3'>サザンスカイハイツ5F-2</p>
                    <img className='Shopphone' src={phone} alt='phone' />
                    <p className='ShopphoneText1'>080-6586-8842</p>
                    <Link to='https://lin.ee/er1evDL'><img className='ShopphoneLINE' src={LINEBk} alt='LINE' /></Link> 
                    <Link to='https://lin.ee/er1evDL'><p className='ShopphoneLINEText'>公式LINEはこちら</p></Link>
                    <img className='ShopphoneInstagram' src={InstagramBk} alt='Instagram' />
                    <p className='ShopphoneInstagramText1'>公式インスタグラム</p>
                    <Link to='https://www.instagram.com/bank_sea_81?igsh=bzJueGdmNjZvenRo'><p className='ShopphoneInstagramText2'>マリンアカウント</p></Link>
                    <Link to='https://www.instagram.com/bank_sea?igsh=MTN4NjEwa3gzb3RzcQ=='><p className='ShopphoneInstagramText3'>イラスト・アートアカウント</p></Link>
                    {/* <button className='ShopButton'>お申し込みはこちら</button> */}
                    <Iframe className='ShopGoogle' frameBorder="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d77260.84569044414!2d124.14474659133117!3d24.371899344748748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34600b3ecbd34873%3A0x4124363c4a5bb8db!2z44CSOTA3LTAwMDQg5rKW57iE55yM55-z5Z6j5biC55m76YeO5Z-O77yZ77yQ77yV4oiS77yS!5e0!3m2!1sja!2sjp!4v1721371170854!5m2!1sja!2sjp" />
                </div>

                <div className='BottomArea1'>
                    {/* <p className='BottomText'><Link>運営元</Link>　|　<Link>サイトについて</Link>　|　<Link>個人情報取扱について</Link></p> */}
                </div>
                <div className='BottomArea2'>
                    <p className='BottomText'>©BANK/SEA ISHIGAKI</p>
                </div>
            </div>

            <div className='Mobileside'>
                <div className='MenuSpArea'>
                    <div className='MenuBar'>
                        <div className='Atari' onClick={() => {MenuClick()}}></div>
                        <div className={UpBar}></div>
                        <div className={DownBar}></div>
                    </div>
                    <div className={SlideMenu}>
                        <p className='MenuSpPos' ><HashLink smooth to='#PageSp1' onClick={() => {MenuClick1()}}>　HOME</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#PageSp2' onClick={() => {MenuClick2()}}>　ABOUT US</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#PageSp3' onClick={() => {MenuClick3()}}>　コース紹介</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#PageSp4' onClick={() => {MenuClick4()}}>　インストラクター</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#PageSp5' onClick={() => {MenuClick5()}}>　フォトギャラリー</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#PageSp6' onClick={() => {MenuClick6()}}>　アクセス</HashLink></p>
                        <p className='MenuSpPos' ><Link to='/QandA' onClick={() => {MenuClick7()}}>　よくあるご質問</Link></p>
                    </div>
                </div>

                <div className='headerspArea' id='PageSp1'>
                    <div className='headerChangeArea'>
                        <img className='ScrollMediaSp' src={TopImg} alt='TopImg' />
                    </div>
                    <img className='headersp1' src={headersp1} alt='headersp1' />
                    <img className='headersp2' src={headersp2} alt='headersp2' />
                    <img className='HeaderSpBANKSEA' src={BANKSEA} alt='BANKSEA' />
                    <p className='HeaderSpTitle1'>Diving is Life</p>
                    <p className='HeaderSpTitle2'>BANK_SEA<br/>ISHIGAKI </p>

                    <p className='HeaderSpTitle3'>
                        ダイバーでありアーティストでもある私が<br/>
                        海の中で貴方に感動の世界を届けたい. . .<br/><br/>
                        「やってみたい」の気持ちを大切に<br/>
                        共に楽しい最高の思い出を作れる場所
                    </p>
                    {/* <p className='HeaderSpTitle4'>ReadMore</p> */}
                    <img className='HeaderSpWave' src={wave} alt='wave' />
                </div>

                <div className='ABOUTSpArea'id='PageSp2'>
                    <p className='AboutSpBackTitle'>ABOUT<br/>US</p>
                    <p className='InstructorSpTitle'>ABOUT US</p>
                    <div className='InstructorSpTitleLine'></div>
                    <div className='InstructorSpNaotoArea'>
                        {/* <p className='InstructorSpNaotoTitle'>SAKAGUCHI<br/>NAOTO</p> */}
                        <p className='ABOUTSpNaotoText'>
                            どうもBANK/SEA ISHIGAKIと申します。<br/>
                            なぜ、BANK/SEA??と思う方も多いと思いますが<br/>
                            私自身が独学で絵を描くのも好きでありダイビングも好きであり<br/>
                            その２つが融合してそのままショップ名となってしまいました！笑<br/>
                            <br/>
                            BANK/SEA ISHIGAKIではみなさんの<br/>
                            「やってみたい」「挑戦したい」という気持ちを大切に<br/>
                            寄り添っていきたいと思っています。<br/>
                            <br/>
                            私自身が元々保育士をやっていたこともあり<br/>
                            子どもたちの不安や心配の中で、それを一緒に乗り越えて<br/>
                            「やってみたい」「出来た!!」に変わったときの喜びを<br/>
                            今度はみなさんと一緒に味わえたら嬉しいです。
                        </p>
                    </div>
                    <img className='ABOUTSpImg' src={naoto} alt='naoto' />
                </div>

                <div className='CorseSpArea' id='PageSp3'>
                    <p className='CorseSpTitle'>コース紹介</p>
                    <div className='CorseTitleSpLine'></div>
                    <p className='CorseBackSpTitle'>SERVICE</p>
                    <div className={Corse1} onClick={() => {CorseClick1()}}>
                        <p className='CorseSpFUNTitle'>FUN diving<span className='CorseSpFUNText1'>ライセンス保持者のコース</span></p>
                        <p className='CorseSpFUNinText1'>地形、マクロ、ワイドと盛りだくさんのポイントから<br/>その日に最高のポイントで潜ります。</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 2ダイブ  ・・・・・・・・・・・・・・・・・・ 18,500円</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 3ダイブ  ・・・・・・・・・・・・・・・・・・ 21,000円</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> フルレンタル   ・・・・・・・・・・・・・・・・ 4000円</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 重器材（BCD.レギュレーター） ・・・・・・・・ 2500円 </p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 軽器材（フィン.マスク.ウェットスーツ）　・・・・1500円</p>
                    </div>
                    <div className={Corse2} onClick={() => {CorseClick2()}}>
                        <p className='CorseSpFUNTitle'>体験ダイビング<span className='CorseSpFUNText1'>初めての方や不安な方向けのコース</span></p>
                        <p className='CorseSpFUNinText1'>１本目はゆっくりと浅瀬のポイントでしっかりと練習をして<br/>２本目ではウミガメやマンタが見れるポイントで泳げます。</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 2ダイブ ・・・・・・・  21,000円（レンタル代込み）</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 3ダイブ ・・・・・・・  26,000円（レンタル代込み）</p>
                    </div>
                    <div className={Corse3} onClick={() => {CorseClick3()}}>
                        <p className='CorseSpFUNTitle'>ライセンス講習<span className='CorseSpFUNText2'>ダイビングを始めていきたい方のコース</span></p>
                        <p className='CorseSpFUNinText1'>「やってみたい」を大切にダイビングスキルや知恵を勉強できます。</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> OW  2日間 ・・・・・・・・・・・・・・・・・  55,000円</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> AOW2日間 ・・・・・・・・・・・・・・・・・  55,000円</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> OW & AOW3日間 ・・・・・・・・・・・・・・ 98,000円</p>
                    </div>
                    <div className={Corse4} onClick={() => {CorseClick4()}}>
                        <p className='CorseSpFUNTitle'>シュノーケル<span className='CorseSpFUNText1'>初めての方や不安な方向けのコース</span></p>
                        <p className='CorseSpFUNinText1'>水面からウミガメや綺麗な珊瑚を楽しめます。</p>
                        <p className='CorseSpFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> １日 ・・・・・・・・・・・・・・・・・・・・ 13,000円</p>
                    </div>
                    <Link to='https://lin.ee/er1evDL'><button className='CorseSpBtn'>申し込みはこちら</button></Link>
                    <div className='CorseSpBottom' ></div>
                </div>

                <div className='InstructorSpArea'id='PageSp4'>
                    <p className='InstructorSpTitle'>インストラクター紹介</p>
                    <div className='InstructorSpTitleLine'></div>
                    <img className='InstructorSpImg' src={member} alt='member' />
                    <img className='InstructorSpWave' src={wave} alt='wave' />
                    <div className='InstructorSpNaotoArea'>
                        <p className='InstructorSpNaotoTitle'>NAOTO OHNO</p>
                        <p className='InstructorSpNaotoText'>大野七音（おおのなおと）</p>
                        <img className='InstructorSpStarfishImg1' src={starfish} alt='starfish' />
                        <p className='InstructorSpText1'>あだ名：ドレミ♪</p>
                        <img className='InstructorSpStarfishImg2' src={starfish} alt='starfish' />
                        <p className='InstructorSpText2'>生年月日：1997/6/17</p>
                        <img className='InstructorSpStarfishImg3' src={starfish} alt='starfish' />
                        <p className='InstructorSpText3'>出身地：大都会埼玉県</p>
                        <img className='InstructorSpStarfishImg4' src={starfish} alt='starfish' />
                        <p className='InstructorSpText4'>
                            資格：PADIインストラクター・潜水士・小型船舶免許二級・<br/>
                            　　　特定小型船舶操縦士・保育士免許・幼稚園教論免許
                        </p>
                        <img className='InstructorSpStarfishImg5' src={starfish} alt='starfish' />
                        <p className='InstructorSpText5'>
                            メッセージ<br/>
                            海なし県で保育士をやっていた際になんとなくきた石垣島...<br/>
                            海の壮大さと沢山の人や生き物との出会いに感激受けあっとい<br/>
                            う間に住み着いてしまいました。<br/>
                            石垣島の海では小さな生物から大きな生物までたくさんの魅力<br/>
                            のある海になっているのでそんな海の世界を皆さんと共に感じ<br/>
                            ることができたら嬉しいです。
                        </p>
                    </div>
                </div>

                <div className='FontGallerySpArea' id='PageSp5'>
                    <p className='FontGallerySpTitle'>フォトギャラリー</p>
                    <div className='FontGallerySpTitleLine'></div>
                    <div className='FontGalleryInstaSpArea'>
                        <div className='FontGallerySpShop'>
                            <img className='FontGallerySpIcon' src={InstaIcon1} alt='FontGalleryIcon' />
                            <p className='FontGallerySpName1'>diving shop</p>
                            <p className='FontGallerySpName2'>bank_sea_81</p>
                            <Link to='https://www.instagram.com/bank_sea_81/' target="_blank">
                                <div className='InstaIconSpWArea'>
                                    <img className='InstaIconSpW' src={InstaIconW} alt='InstaIconW' />
                                    <p className='InstaIconSpWText'>Instagramでフォロー</p>
                                </div>
                            </Link>
                            <Link to='https://www.instagram.com/p/C9tz6mZy0R_/?img_index=1' target="_blank"><img className='FontGallerySpImg1' src={InstaImg1} alt='FontGalleryImg1' /></Link>
                            <Link to='https://www.instagram.com/p/C9rdWPqSrOT/' target="_blank"><img className='FontGallerySpImg1' src={InstaImg2} alt='FontGalleryImg1' /></Link>
                            <Link to='https://www.instagram.com/p/C9mpO36ypRc/?img_index=1' target="_blank"><img className='FontGallerySpImg1' src={InstaImg3} alt='FontGalleryImg1' /></Link>
                        </div>
                        <div className='FontGallerySpArt'>
                            <img className='FontGallerySpIcon' src={InstaIcon2} alt='FontGalleryIcon' />
                            <p className='FontGallerySpName1'>illustration/art</p>
                            <p className='FontGallerySpName2'>bank_sea</p>
                            <Link to='https://www.instagram.com/bank_sea/following/' target="_blank">
                                <div className='InstaIconSpWArea'>
                                    <img className='InstaIconSpW' src={InstaIconW} alt='InstaIconW' />
                                    <p className='InstaIconSpWText'>Instagramでフォロー</p>
                                </div>
                            </Link>
                            <Link to='https://www.instagram.com/p/CulvxqPPAiH/?img_index=1' target="_blank"><img className='FontGallerySpImg1' src={InstaImg4} alt='FontGalleryImg1' /></Link>
                            <Link to='https://www.instagram.com/p/CrLDCXRvZeW/' target="_blank"><img className='FontGallerySpImg1' src={InstaImg5} alt='FontGalleryImg1' /></Link>
                            <Link to='https://www.instagram.com/p/CzlOcGcpm43/' target="_blank"><img className='FontGallerySpImg1' src={InstaImg6} alt='FontGalleryImg1' /></Link>
                        </div>
                    </div>
                </div>

                <div className='ShopSpArea' id='PageSp6'>
                    <p className='ShopSpTitle'>アクセス</p>
                    <div className='ShopSpTitleLine'></div>
                    <p className='ShopSpBackTitle'>ABOUTSHOP</p>
                    <img className='ShopcSplock' src={clock} alt='clock' />
                    <p className='ShopclockSpText1'>[ 営業時間 ]　7:00〜20:00</p>
                    <img className='ShoppSplace' src={place} alt='place' />
                    <p className='ShopplaceSpText1'>〒907-0004</p>
                    <p className='ShopplaceSpText2'>沖縄県石垣市登野城905-2</p>
                    <p className='ShopplaceSpText3'>サザンスカイハイツ5F-2</p>
                    <img className='ShoppSphone' src={phone} alt='phone' />
                    <p className='ShopphoneSpText1'>080-6586-8842</p>
                    <Link to='https://lin.ee/er1evDL'><img className='ShoppSpLINEBk' src={LINEBk} alt='LINE' /></Link> 
                    <Link to='https://lin.ee/er1evDL'><p className='ShoppLINEBkSpText1'>公式LINEはこちら</p></Link>
                    <img className='ShoppSpInstagramBk' src={InstagramBk} alt='Instagram' />
                    <p className='ShoppInstagramBkSpText1'>公式インスタグラム</p>
                    <Link to='https://www.instagram.com/bank_sea_81?igsh=bzJueGdmNjZvenRo'><p className='ShoppInstagramBkSpText2'>マリンアカウント</p></Link>
                    <Link to='https://www.instagram.com/bank_sea?igsh=MTN4NjEwa3gzb3RzcQ=='><p className='ShoppInstagramBkSpText3'>イラスト・アートアカウント</p></Link>
                    <Link to='https://lin.ee/er1evDL'><button className='ShopSpButton'>お申し込みはこちら</button></Link>
                    <Iframe className='ShopSpGoogle' frameBorder="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d77260.84569044414!2d124.14474659133117!3d24.371899344748748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34600b3ecbd34873%3A0x4124363c4a5bb8db!2z44CSOTA3LTAwMDQg5rKW57iE55yM55-z5Z6j5biC55m76YeO5Z-O77yZ77yQ77yV4oiS77yS!5e0!3m2!1sja!2sjp!4v1721371170854!5m2!1sja!2sjp" />
                </div>

                <div className='BottomSpArea1'>
                    {/* <p className='BottomSpText'><Link>運営元</Link>　|　<Link>サイトについて</Link>　|　<Link>個人情報取扱について</Link></p> */}
                </div>
                <div className='BottomSpArea2'>
                    <p className='BottomSpText'>©BANK/SEA ISHIGAKI</p>
                </div>
            </div>
        </div>
    )
}