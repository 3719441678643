import '../css/Style.css'
import { useState, useEffect } from 'react'
import useInterval from 'use-interval'
import { Link } from 'react-router-dom'
import Iframe from 'react-iframe';
import { HashLink } from 'react-router-hash-link'

import img001 from '../image/main_001.png'
import img002 from '../image/main_002.png'
import img003 from '../image/main_003.png'
import img004 from '../image/main_004.png'
import img005 from '../image/main_005.png'
import img006 from '../image/main_006.png'
import img007 from '../image/main_007.png'
import img008 from '../image/main_008.png'
import img009 from '../image/main_009.png'
import img010 from '../image/main_010.png'

import header from '../image/header-bg.png'
import wave from '../image/wave-yellow.png'
import fish from '../image/fish.png'
import giphy from '../image/giphy.gif'
import BANKSEA from '../image/BANKSEA_logo.jpg'
import Instagram from '../image/Instagram.png'
import LINEBk from '../image/LINE-bk.svg'
import InstagramBk from '../image/Instagram-bk.png'
import InstaIcon1 from '../image/insta-icon1.png'
import InstaIcon2 from '../image/insta-icon2.png'
import InstaImg1 from '../image/Insta-img1.png'
import InstaImg2 from '../image/Insta-img2.png'
import InstaImg3 from '../image/Insta-img3.png'
import InstaImg4 from '../image/Insta-img4.png'
import InstaImg5 from '../image/Insta-img5.png'
import InstaImg6 from '../image/Insta-img6.png'
import InstaIconW from '../image/instagram_white_icon.svg'
import clock from '../image/clock.svg'
import place from '../image/place.svg'
import phone from '../image/phone.svg'
import checkmark from '../image/checkmark.svg'
import naoto from '../image/naoto.png'
import member from '../image/member-001.png'
import starfish from '../image/starfish.svg'

export default function PC ({ PageDisplay, setPageDisplay }) {

    useEffect(() => {
        const handleReload = () => {
          window.location.href = '/'; // リダイレクト先のURL
        };
    
        window.addEventListener('/', handleReload);
    
        return () => {
          window.removeEventListener('/', handleReload);
        };
      }, []);
    
    const [TopMenu1, setTopMenu1] = useState('MenuPos Menu1');
    const [TopMenu2, setTopMenu2] = useState('MenuPos Menu2');
    const [TopMenu3, setTopMenu3] = useState('MenuPos Menu2');
    const [TopMenu4, setTopMenu4] = useState('MenuPos Menu2');
    const [TopMenu5, setTopMenu5] = useState('MenuPos Menu2');
    const [TopMenu6, setTopMenu6] = useState('MenuPos Menu2');

    useState(() => {
        if(PageDisplay === 'Page1'){
            setTopMenu1('MenuPos Menu1');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page2'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu1');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page3'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu1');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page4'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu1');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page5'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu1');
            setTopMenu6('MenuPos Menu2');
        }
        if(PageDisplay === 'Page6'){
            setTopMenu1('MenuPos Menu2');
            setTopMenu2('MenuPos Menu2');
            setTopMenu3('MenuPos Menu2');
            setTopMenu4('MenuPos Menu2');
            setTopMenu5('MenuPos Menu2');
            setTopMenu6('MenuPos Menu1');
        }
    })
    useEffect(() => {
        console.log(OutoPoint + 'だよ！');
    })

    const [OutoPoint, setOutoPoint] = useState(true);

    useInterval(() => {
        setPageDisplay(PageDisplay);
        const targetElement = document.getElementById(PageDisplay);
        if (OutoPoint === true) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            setOutoPoint(false);
        }
    },[100])

    const MenuClick1 = () => {
        setTopMenu1('MenuPos Menu1');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setPageDisplay('Page1');
    }
    const MenuClick2 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu1');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setPageDisplay('Page2');
    }
    const MenuClick3 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu1');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setPageDisplay('Page3');
    }
    const MenuClick4 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu1');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setPageDisplay('Page4');
    }
    const MenuClick5 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu1');
        setTopMenu6('MenuPos Menu2');
        setPageDisplay('Page5');
    }
    const MenuClick6 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu1');
        setPageDisplay('Page6');
    }
    const MenuClick7 = () => {
        setPageDisplay('Page1');
    }

    const [TopImg, setTopImg] = useState(img008);

    const[Count, setCount] = useState(0);
    const[CountUp, setCountUp] = useState(1);
    useInterval(() => {
        setCountUp(1);
        setCount(Count + CountUp);
        console.log(Count);

        if(4 > Count){
            setTopImg(img008);
        }
        if(Count >= 4){
            setTopImg(img001);
        }
        if(Count >= 8){
            setTopImg(img002);
        }
        if(Count >= 12){
            setTopImg(img003);
        }
        if(Count >= 16){
            setTopImg(img004);
        }
        if(Count >= 20){
            setTopImg(img005);
        }
        if(Count >= 24){
            setTopImg(img006);
        }
        if(Count >= 28){
            setTopImg(img007);
        }
        if(Count >= 32){
            setTopImg(img009);
        }
        if(Count >= 36){
            setTopImg(img010);
        }
        if(Count >= 40){
            setCount(0);
        }
    },[1000])
    
    return(
        
        <div className='Base'>
            <div className='MenuArea'>
                <p className={TopMenu1} ><HashLink smooth to='#Page1' onClick={() => {MenuClick1()}}>HOME</HashLink></p>
                <p className={TopMenu2} ><HashLink smooth to='#Page2' onClick={() => {MenuClick2()}}>ABOUT US</HashLink></p>
                <p className={TopMenu3} ><HashLink smooth to='#Page3' onClick={() => {MenuClick3()}}>コース紹介</HashLink></p>
                <p className={TopMenu4} ><HashLink smooth to='#Page4' onClick={() => {MenuClick4()}}>インストラクター</HashLink></p>
                <p className={TopMenu5} ><HashLink smooth to='#Page5' onClick={() => {MenuClick5()}}>フォトギャラリー</HashLink></p>
                <p className={TopMenu6} ><HashLink smooth to='#Page6' onClick={() => {MenuClick6()}}>アクセス</HashLink></p>
                <p className='MenuPos Menu2' ><Link to='/QandA' onClick={() => {MenuClick7()}}>よくあるご質問</Link></p>
                {/* <p className='MenuPos Menu2' >お問い合わせ</p> */}
            </div>

            <div className='TopMedia'>
                <img className='ScrollMedia' src={TopImg} alt='TopImg' />
            </div>
            <img className='HeaderGiphy' src={giphy} alt='giphy' />
            <div className='BubbleTextArea'>
                <p className='BubbleText1'>DIVING IS LIFE</p>
                <p className='BubbleText2'>DIVING IS LIFE</p>
                <p className='BubbleText3'>DIVING IS LIFE</p>
            </div>
            <div className='HeaderArea' id='Page1'>
                <img className='HeaderBANKSEA' src={BANKSEA} alt='BANKSEA' />
                <img className='HeaderMedia' src={header} alt='header' />
                <p className='HeaderTitle1'>Diving is Life</p>
                <p className='HeaderTitle2'>BANK_SEA<br/>ISHIGAKI </p>
                <p className='HeaderTitle3'>
                    ダイバーでありアーティストでもある私が海の中で<br/>
                    貴方に感動の世界を届けたい. . .<br/>
                    「やってみたい」の気持ちを大切に<br/>
                    共に楽しい最高の思い出を作れる場所
                </p>
                {/* <p className='HeaderTitle4'>ReadMore</p> */}
                <img className='HeaderWave' src={wave} alt='wave' />
                {/* <Link to='https://lin.ee/er1evDL'><img className='HeaderLINE' src={LINE} alt='LINE' /></Link> */}
                <Link to='https://www.instagram.com/bank_sea_81?igsh=bzJueGdmNjZvenRo'><img className='HeaderInstagram' src={Instagram} alt='Instagram' /></Link>
                <p className='HeaderTitle5'>www.BANK_SEA ISHIGAKI .com</p>
            </div>

            <div className='AboutArea' id='Page2'>
                <p className='AboutBackTitle'>ABOUT<br/>US</p>
                <img className='HeaderFish' src={fish} alt='fish' />
                <p className='AboutTitle'>ABOUT US</p>
                <div className='AboutTitleLine'></div>
                <p className='AboutText'>
                    どうもBANK/SEA ISHIGAKIと申します。<br/>
                    なぜ、BANK/SEA??と思う方も多いと思いますが<br/>
                    私自身が独学で絵を描くのも好きでありダイビングも好きであり<br/>
                    その２つが融合してそのままショップ名となってしまいました！笑<br/>
                    <br/>
                    BANK/SEA ISHIGAKIではみなさんの<br/>
                    「やってみたい」「挑戦したい」という気持ちを大切に<br/>
                    寄り添っていきたいと思っています。<br/>
                    <br/>
                    私自身が元々保育士をやっていたこともあり<br/>
                    子どもたちの不安や心配の中で、それを一緒に乗り越えて<br/>
                    「やってみたい」「出来た!!」に変わったときの喜びを<br/>
                    今度はみなさんと一緒に味わえたら嬉しいです。
                </p>
                <img className='AboutImg' src={naoto} alt='naoto' />
            </div>

            <div className='CorseArea' id='Page3'>
                <p className='CorseTitle'>コース紹介</p>
                <div className='CorseTitleLine'></div>
                <p className='CorseBackTitle'>SERVICE</p>

                <div className='CorseFUN'>
                    <p className='CorseFUNTitle'>FUN diving<span className='CorseFUNText'>ライセンス保持者のコース</span></p>
                    <div className='CorseFUNin'>
                        <p className='CorseFUNinText1'>地形、マクロ、ワイドと盛りだくさんのポイントから<br/>その日に最高のポイントで潜ります。</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 2ダイブ  ・・・・・・・・・・・・・・・・・・ 18,500円</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 3ダイブ  ・・・・・・・・・・・・・・・・・・ 21,000円</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> フルレンタル   ・・・・・・・・・・・・・・・・ 4000円</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 重器材（BCD.レギュレーター） ・・・・・・・・ 2500円 </p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 軽器材（フィン.マスク.ウェットスーツ）　・・・・1500円</p>
                        {/* <button className='CorseFUNinBtn'>申し込む</button> */}
                    </div>
                </div>
                <p className='CorseFUNCaution'>※講習生はレンタル器材永久無料</p>

                <div className='CorseNovice'>
                    <p className='CorseFUNTitle'>体験ダイビング<span className='CorseNoviceText'>初めての方や不安な方向けのコース</span></p>
                    <div className='CorseNovicein'>
                        <p className='CorseFUNinText1'>１本目はゆっくりと浅瀬のポイントでしっかりと練習をして<br/>２本目ではウミガメやマンタが見れるポイントで泳げます。</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 2ダイブ ・・・・・・・  21,000円（レンタル代込み）</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> 3ダイブ ・・・・・・・  26,000円（レンタル代込み）</p>
                        {/* <button className='CorseNoviceinBtn'>申し込む</button> */}
                    </div>
                </div>

                <div className='CorseLicense'>
                    <p className='CorseLicenseTitle'>ライセンス講習<span className='CorseLicenseText'>ダイビングを始めていきたい方のコース</span></p>
                    <div className='CorseLicensein'>
                        <p className='CorseFUNinText1'>「やってみたい」を大切にダイビングスキルや知恵を勉強できます。</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> OW  2日間 ・・・・・・・・・・・・・・・・・  55,000円</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> AOW2日間 ・・・・・・・・・・・・・・・・・  55,000円</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> OW & AOW3日間 ・・・・・・・・・・・・・・ 98,000円</p>
                        {/* <button className='CorseNoviceinBtn'>申し込む</button> */}
                    </div>
                </div>

                <div className='CorseSchnorchel'>
                    <p className='CorseFUNTitle'>シュノーケル<span className='CorseNoviceText'>初めての方や不安な方向けのコース</span></p>
                    <div className='CorseNovicein'>
                        <p className='CorseFUNinText1'>水面からウミガメや綺麗な珊瑚を楽しめます。</p>
                        <p className='CorseFUNinText2'><img className='checkmark' src={checkmark} alt='checkmark'/> １日 ・・・・・・・・・・・・・・・・・・・・ 13,000円</p>
                        {/* <button className='CorseSchnorchelinBtn'>申し込む</button> */}
                    </div>
                </div>

                <Link to='https://lin.ee/er1evDL'><button className='CorseBtn'>お申込みは公式LINEから</button></Link>
            </div>

            <div className='InstructorArea'id='Page4'>
                <p className='InstructorTitle'>インストラクター紹介</p>
                <div className='InstructorTitleLine'></div>
                <img className='InstructorImg' src={member} alt='member' />
                <img className='Instructorwave' src={wave} alt='wave' />
                <div className='InstructorNaotoArea'>
                    <p className='InstructorNaotoTitle'>NAOTO OHNO</p>
                    <p className='InstructorNaotoText'>大野七音（おおのなおと）</p>
                    <img className='InstructorStarfishImg1' src={starfish} alt='starfish' />
                    <p className='InstructorText1'>あだ名：ドレミ♪</p>
                    <img className='InstructorStarfishImg2' src={starfish} alt='starfish' />
                    <p className='InstructorText2'>生年月日：1997/6/17</p>
                    <img className='InstructorStarfishImg3' src={starfish} alt='starfish' />
                    <p className='InstructorText3'>出身地：大都会埼玉県</p>
                    <img className='InstructorStarfishImg4' src={starfish} alt='starfish' />
                    <p className='InstructorText4'>
                        資格：PADIインストラクター・潜水士・小型船舶免許二級・<br/>
                        　　　特定小型船舶操縦士・保育士免許・幼稚園教論免許
                    </p>
                    <img className='InstructorStarfishImg5' src={starfish} alt='starfish' />
                    <p className='InstructorText5'>
                        メッセージ<br/>
                        海なし県で保育士をやっていた際になんとなくきた石垣島...<br/>
                        海の壮大さと沢山の人や生き物との出会いに感激受けあっとい<br/>
                        う間に住み着いてしまいました。<br/>
                        石垣島の海では小さな生物から大きな生物までたくさんの魅力<br/>
                        のある海になっているのでそんな海の世界を皆さんと共に感じ<br/>
                        ることができたら嬉しいです。
                    </p>
                </div>
            </div>

            <div className='FontGalleryArea' id='Page5'>
                <p className='FontGalleryTitle'>フォトギャラリー</p>
                <div className='FontGalleryTitleLine'></div>
                <div className='FontGalleryInstaArea'>
                    <div className='FontGalleryShop'>
                        <img className='FontGalleryIcon' src={InstaIcon1} alt='FontGalleryIcon' />
                        <p className='FontGalleryName1'>diving shop</p>
                        <p className='FontGalleryName2'>bank_sea_81</p>
                        <Link to='https://www.instagram.com/bank_sea_81/' target="_blank">
                            <div className='InstaIconWArea'>
                                <img className='InstaIconW' src={InstaIconW} alt='InstaIconW' />
                                <p className='InstaIconWText'>Instagramでフォロー</p>
                            </div>
                        </Link>
                        <Link to='https://www.instagram.com/p/C9tz6mZy0R_/?img_index=1' target="_blank"><img className='FontGalleryImg1' src={InstaImg1} alt='FontGalleryImg1' /></Link>
                        <Link to='https://www.instagram.com/p/C9rdWPqSrOT/' target="_blank"><img className='FontGalleryImg1' src={InstaImg2} alt='FontGalleryImg1' /></Link>
                        <Link to='https://www.instagram.com/p/C9mpO36ypRc/?img_index=1' target="_blank"><img className='FontGalleryImg1' src={InstaImg3} alt='FontGalleryImg1' /></Link>
                    </div>
                    <div className='FontGalleryArt'>
                        <img className='FontGalleryIcon' src={InstaIcon2} alt='FontGalleryIcon' />
                        <p className='FontGalleryName1'>illustration/art</p>
                        <p className='FontGalleryName2'>bank_sea</p>
                        <Link to='https://www.instagram.com/bank_sea/following/' target="_blank">
                            <div className='InstaIconWArea'>
                                <img className='InstaIconW' src={InstaIconW} alt='InstaIconW' />
                                <p className='InstaIconWText'>Instagramでフォロー</p>
                            </div>
                        </Link>
                        <Link to='https://www.instagram.com/p/CulvxqPPAiH/?img_index=1' target="_blank"><img className='FontGalleryImg1' src={InstaImg4} alt='FontGalleryImg1' /></Link>
                        <Link to='https://www.instagram.com/p/CrLDCXRvZeW/' target="_blank"><img className='FontGalleryImg1' src={InstaImg5} alt='FontGalleryImg1' /></Link>
                        <Link to='https://www.instagram.com/p/CzlOcGcpm43/' target="_blank"><img className='FontGalleryImg1' src={InstaImg6} alt='FontGalleryImg1' /></Link>
                    </div>
                </div>
            </div>

            <div className='ShopArea' id='Page6'>
                <p className='ShopTitle'>アクセス</p>
                <div className='ShopTitleLine'></div>
                <p className='ShopBackTitle'>ACCESS</p>
                <img className='Shopclock' src={clock} alt='clock' />
                <p className='ShopclockText1'>[ 営業時間 ]　7:00〜20:00</p>
                <p className='ShopclockText2'>[ 定休日 ]　　毎週○よう日</p>
                <img className='Shopplace' src={place} alt='place' />
                <p className='ShopplaceText1'>〒907-0004</p>
                <p className='ShopplaceText2'>沖縄県石垣市登野城905-2</p>
                <p className='ShopplaceText3'>サザンスカイハイツ5F-2</p>
                <img className='Shopphone' src={phone} alt='phone' />
                <p className='ShopphoneText1'>080-6586-8842</p>
                <Link to='https://lin.ee/er1evDL'><img className='ShopphoneLINE' src={LINEBk} alt='LINE' /></Link> 
                <Link to='https://lin.ee/er1evDL'><p className='ShopphoneLINEText'>公式LINEはこちら</p></Link>
                <img className='ShopphoneInstagram' src={InstagramBk} alt='Instagram' />
                <p className='ShopphoneInstagramText1'>公式インスタグラム</p>
                <Link to='https://www.instagram.com/bank_sea_81?igsh=bzJueGdmNjZvenRo'><p className='ShopphoneInstagramText2'>マリンアカウント</p></Link>
                <Link to='https://www.instagram.com/bank_sea?igsh=MTN4NjEwa3gzb3RzcQ=='><p className='ShopphoneInstagramText3'>イラスト・アートアカウント</p></Link>
                {/* <button className='ShopButton'>お申し込みはこちら</button> */}
                <Iframe className='ShopGoogle' frameBorder="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d77260.84569044414!2d124.14474659133117!3d24.371899344748748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34600b3ecbd34873%3A0x4124363c4a5bb8db!2z44CSOTA3LTAwMDQg5rKW57iE55yM55-z5Z6j5biC55m76YeO5Z-O77yZ77yQ77yV4oiS77yS!5e0!3m2!1sja!2sjp!4v1721371170854!5m2!1sja!2sjp" />
            </div>

            <div className='BottomArea1'>
                {/* <p className='BottomText'><Link>運営元</Link>　|　<Link>サイトについて</Link>　|　<Link>個人情報取扱について</Link></p> */}
            </div>
            <div className='BottomArea2'>
                <p className='BottomText'>©BANK/SEA ISHIGAKI</p>
            </div>
        </div>
    )
}